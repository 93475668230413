import { Location } from '@zupr/types/fo'
import React, { useCallback, useContext } from 'react'

import RouteContext from '../../../context/route'
import Tooltip from '../../components/tooltip'
import Trans from '../../components/trans'
import ExternalLink from '../../router/link-external'

import LinkIcon from '../../../../svg/globe.svg'

import '../../../../scss/react/components/action-links.scss'

interface WebsiteLinkProps {
    location: Location
    className?: string
    children?: React.ReactNode
    onClick?: () => void
    iconOnly?: boolean
}

const WebsiteLink = ({
    location,
    className,
    children,
    onClick,
    iconOnly,
}: WebsiteLinkProps) => {
    const { trackEvent } = useContext(RouteContext)

    const handleClick = useCallback(() => {
        // track event
        trackEvent({
            id: location.id,
            action: 'clickWebsite',
        })

        if (onClick) onClick()
    }, [location.id, onClick, trackEvent])

    return (
        <ExternalLink
            className={className}
            href={location.website}
            onClick={handleClick}
            aria-label="Visit website"
        >
            {iconOnly && <LinkIcon />}
            {!iconOnly && (
                <>
                    <span className="btn-social social-platform">
                        <LinkIcon />
                    </span>
                    {children || (
                        <span>
                            <Trans label="Visit website" />
                        </span>
                    )}
                </>
            )}
        </ExternalLink>
    )
}

export const WebsiteAction = ({ ...props }: WebsiteLinkProps) => (
    <Tooltip label="Visit website">
        <WebsiteLink className="btn btn-small" iconOnly {...props} />
    </Tooltip>
)

export default WebsiteLink
