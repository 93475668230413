import '../../../../scss/react/frontoffice/grid-summary.scss'
import Checkmark from '../../../../svg/checkmark.svg'
interface Item {
    label: string
    success?: boolean
}

interface Props {
    children?: React.ReactNode
    items?: Item[]
}

export function GridSummaryItem({ label, success }: Item) {
    return (
        <li>
            {(typeof success === 'undefined' || success) && <Checkmark />}
            <span>{label}</span>
        </li>
    )
}

export default function GridSummary({ items, children }: Props) {
    if (!items?.length && !children) {
        return null
    }
    return (
        <ul className="grid-summary">
            {items?.map((item) => (
                <GridSummaryItem {...item} key={item.label} />
            ))}
            {children}
        </ul>
    )
}
